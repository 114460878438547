import React, {useContext, useEffect, useState, useRef} from "react";
import styles from "./ChatHistory.module.css"
// import { getApi } from '../../services/apiService';
import {AppContext} from "../../redux/AppContext";
// import {useMsal} from "@azure/msal-react";
import MinModal from "../MinModal/MinModal";
import {ThemeContext} from "../../redux/ThemeContext";
import MaxModal from "../MaxModal/MaxModal";
import {setCurrentChatId} from "../../redux/actions";
import {useNavigate} from "react-router-dom";
import {teal} from "@mui/material/colors";
import ContextModal from "../ContextModal/ContextModal";
import Busy from "../Busy/Busy";
// import chat from "../Chat/Chat";
import { useApi } from "../../hooks/useApi"
import {FaBookmark, FaCopy, FaDisplay, FaRegBookmark, FaTrash, FaXmark} from "react-icons/fa6";
import {FaEdit, FaRobot, FaSearch, FaUser} from "react-icons/fa";

// Debounce function
let debounceTimer;
const debounce = (func, delay) => {
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }
}

function ChatHistory() {
    const [loading, setLoading] = useState(false);
    const [chatHistory, setChatHistory] = useState([]);
    const [chatHistoryIsAll, setChatHistoryIsAll] = useState(true);
    // const { instance } = useMsal();
    const { state, dispatch } = useContext(AppContext);
    const { currentProject, chatId } = state;
    const [isRenameModalOpen, setRenameModalOpen] = useState(false);
    const [selectedChatToAction, setSelectedChatToAction] = useState(null);
    const [chatNewTitle, setChatNewTitle] = useState('');
    const { theme } = useContext(ThemeContext);
    const navigate = useNavigate();
    const [lastId, setLastId] = useState(null);
    const [forceReloadWithDelay, setForceReloadWithDelay] = useState(null);
    const [isBookmarked, setBookmarked] = useState(false);
    const [query, setQuery] = useState(null);
    const [chatSearchText, setChatSearchText] = useState('');
    const scrollDivRef = useRef(null);
    // const [showDuplicateChatModal, setShowDuplicateModal] = useState(false);
    const [isDuplicateModalOpen, setDuplicateModalOpen] = useState(false);
    const [duplicateParameters, setDuplicateParameters] = useState([]);

    const currentProjectRef = useRef(currentProject);
    const chatIdRef = useRef(chatId);

    useEffect(() => {
        currentProjectRef.current = currentProject;
        chatIdRef.current = chatId;
    }, [currentProject, chatId]);

    const api = useApi();

    useEffect(() => {
        if (currentProject) {
            loadChatHistory();
        }
    }, [currentProject]);

    useEffect(() => {
        if (chatId && !chatHistory.some(d=> d.id === chatId )) {
            loadChatHistory();
        }
    }, [chatId]);

    // useEffect(() => {
    //     loadBalance();
    //     // loadRanking();
    // }, [chatId]);

    useEffect(()=> {
        if (forceReloadWithDelay) {
            setTimeout(() => {
                loadChatHistory();
            }, 10000)
        }
    },[forceReloadWithDelay])




    const loadChatHistory = (_isBookmarked = isBookmarked, _query = chatSearchText) => {
        // const loadingProjectId =currentProject.id
        api.get("/api/chats/search/titles", {
            params: {
                project_id: currentProject.id,
                selected_chat_id: chatIdRef.current,
                is_bookmarked: _isBookmarked,
                q: _query
            }
        })
            .then(response => {
                if (response.data.project_id !== currentProjectRef.current.id) {
                    console.log( 'oudated project id. discard')
                    return;
                }
                setChatHistoryIsAll(response.data?.is_all);
                const dataList = response.data?.list || [];
                setChatHistory(dataList);
                if (dataList.length > 0) {
                    setLastId(dataList[dataList.length - 1].id);
                    const current = dataList.find(chat => chat.id == chatId);
                    if (current?.title == "(no name)" || current?.title?.startsWith("Cont.")) {
                        setForceReloadWithDelay(chatId)
                    } else {
                    }
                }
            })
            .catch((error)=> { /*error handled in apiService*/});
    }
    const loadChatHistoryMore = () => {
        if (chatHistoryIsAll) return;
        api.get("/api/chats/search/titles", {
            params: {
                project_id: currentProject.id,
                // selected_chat_id: chatId,
                last_id: lastId,
                is_bookmarked: isBookmarked,
                q: chatSearchText
            }
        })
            .then(response => {
                setChatHistoryIsAll(response.data?.is_all);
                const dataList = response.data?.list || [];
                if (dataList.length > 0) {
                    setChatHistory([...chatHistory, ...dataList]);
                    setLastId(dataList[dataList.length - 1].id);
                }
            })
            .catch((error)=> { /*error handled in apiService*/});
    }

    const handleChatSearch = debounce((_query) => {
        loadChatHistory(isBookmarked, _query);
    }, 500); // 500ms delay

    useEffect(() => {
        const scrollDiv = scrollDivRef.current;
        if (scrollDiv) {
            const handleScroll = () => {
                // Check if we're at the bottom of the div
                if (((scrollDiv.scrollTop + scrollDiv.clientHeight) - scrollDiv.scrollHeight) >=0) {
                    loadChatHistoryMore();
                }
            };

            scrollDiv.addEventListener('scroll', handleScroll);

            // Cleanup function to remove the event listener
            return () => scrollDiv.removeEventListener('scroll', handleScroll);
        }
    }, [loadChatHistory,loadChatHistoryMore]);


    const handleChatHistoryClick = async (chatId) => {
        await dispatch(setCurrentChatId(chatId))
        navigate(`/chat/${chatId}`)
        // selectChat(chatId)
    }
    const handleDuplicate= async (chatId) => {
        await api.post(`/api/chats/${chatId}/duplicate`);
        await loadChatHistory()
    }


    const handleRename= async (chatId, newTitle) => {
        await api.put(`/api/chats/${chatId}/rename`, {
            title: newTitle
        });
        await loadChatHistory()
    }

    const toggleChatBookmark = async (chatId) => {
        setLoading(true);
        try {
            await api.put(`/api/bookmarks/${chatId}/toggle`);
            await loadChatHistory()
        } finally {
            setLoading(false);
        }
    }

    const handleDeleteChat= async (deleteChatId, title) => {
        if (window.confirm(`Delete entire conversation of "${title}"?`)) {
            await api.delete(`/api/chats/${deleteChatId}`);
            setChatHistory( chatHistory.filter( chat=> chat.id !== deleteChatId));
            if (deleteChatId == chatId) {
                dispatch( setCurrentChatId(null));
            }
            // await loadChatHistory()
        }
    }

    const handleSelectiveDuplicate= async (chatId, title, clickPosition) => {
        setLoading(true);
        try {
            const result = await api.get(`/api/chats/${chatId}/entries-selection-list`);
            const selectedEntries = result.data.reduce((acc, d) => {
                acc[d.entry_id] = true;
                return acc;
            }, {})
            setDuplicateParameters({
                entriesList: result.data,
                chatId,
                clickPosition,
                selectedEntries,
                title
            })
            setDuplicateModalOpen(true)
        } finally {
            setLoading(false);
        }
    }

    const handleDuplicateSelection = (entry_id) => {
        setDuplicateParameters((prevState) => ({
            ...prevState,
            selectedEntries: {
                ...prevState.selectedEntries,
                [entry_id]: !prevState.selectedEntries[entry_id]
            }
        }));
    };

    async function handleExecSelectiveDuplicate() {
        setLoading(true);
        try {
            const response = await api.post(`/api/chats/${chatId}/selective-duplicate`, {
                title: duplicateParameters.title,
                entries: Object.keys(duplicateParameters.selectedEntries).filter(id => duplicateParameters.selectedEntries[id])
            });
            setDuplicateModalOpen(false);
            await loadChatHistory()
            dispatch( setCurrentChatId(response.data));
        } finally {
            setLoading(false);
        }
    }



    const duplicateModal = isDuplicateModalOpen && (
        <ContextModal
            show={isDuplicateModalOpen}
            clickPosition={duplicateParameters.clickPosition}
            handleClose = {()=> setDuplicateModalOpen(false)}
        >
            <div className={styles["duplicate-modal-container"]}>
                <div  className={styles["duplicate-modal-entries"]}>
                    {(duplicateParameters.entriesList || []).map((m, index)=> (
                        <div className={`${styles["duplicate-modal-row"]} list-item`}
                             onClick={() => handleDuplicateSelection(m.entry_id)}
                        >
                            <div>
                                {!!duplicateParameters.selectedEntries[m.entry_id] && <img src ="/icons8-checked-48.png" alt="selected"/>}
                            </div>
                            {/*<div>{m.role}</div>*/}
                            <div className={styles["icon-role"]}>
                                {m.role === "system" && <FaDisplay />}
                                {m.role === "user" && <FaUser/>}
                                {m.role === "assistant" && <FaRobot />}
                                {/*{m.role == "system" && <img*/}
                                {/*    src={theme == "dark" ? "/icons8-system-50--dark.png" : "/icons8-system-50--light.png"}*/}
                                {/*/>}*/}
                                {/*{m.role == "user" && <img*/}
                                {/*    src={theme == "dark" ? "/icons8-user-60-dark.png" : "/icons8-user-60-light.png"}*/}
                                {/*/>}*/}
                                {/*{m.role == "assistant" && <img*/}
                                {/*    src={theme == "dark" ? "/icons8-bot-50--dark.png" : "/icons8-bot-50--light.png"}*/}
                                {/*/>}*/}
                            </div>
                            <div>{m.content}</div>
                        </div>
                    ))}
                </div>
                <div className={styles["duplicate-modal-panel"]}>
                    <div  className={styles["duplicate-modal-panel-title"]}>
                        New title: <input type={"text"} value={duplicateParameters.title}
                                          onChange = {e => {
                                              setDuplicateParameters((prevState) => ({
                                                  ...prevState,
                                                  title: e.target.value
                                              }));
                                          }}/>
                    </div>
                    <button
                        className="button"
                        onClick={async () => {
                            await handleExecSelectiveDuplicate()
                        }}>Duplicate
                    </button>
                </div>
            </div>
        </ContextModal>
    )


    const renameModal = !!isRenameModalOpen && (
        <ContextModal
            show={isRenameModalOpen}
            clickPosition = {isRenameModalOpen}
            handleClose={() => {
                setRenameModalOpen( false);
            }}
        //     handleSave={async () => {
        //     console.log('save');
        //     await handleRename(selectedChatToAction.id, chatNewTitle)
        //     setRenameModalOpen(false);
        // }}
        >
            <div className={`${styles["modal-container"]} context-modal-margin`}>
                <input
                    type={"text"}
                    className={"input"}
                    autoFocus
                    value={chatNewTitle}
                    onChange = {e=>setChatNewTitle(e.target.value)}/>
                <button
                    className={"button"}
                    onClick = {async () => {
                        await handleRename(selectedChatToAction.id, chatNewTitle);
                        setRenameModalOpen(null);
                    }}
                >Save</button>
            </div>
        </ContextModal>
    )

    function toggleBookmarked() {
        loadChatHistory( !isBookmarked, chatSearchText);
        setBookmarked((prevState)=> !prevState)
    }




    const divPanel = (
        <div className={styles["panel"]}>
            <div className={styles["search-box"]}>
                <input
                    type={"text"}
                    className = {"input"}
                    value={chatSearchText}
                    onChange={e => {
                        setChatSearchText(e.target.value);
                        handleChatSearch(e.target.value);
                    }}/>
                {!chatSearchText && <div className={`fa-icon ${styles["search-box-icon"]}`}
                    >
                    <FaSearch/>
                    {/*<img*/}
                    {/*    src={theme == "dark" ? "/icons8-search-50-dark.png" : "/icons8-search-50-light.png"}*/}
                    {/*/>*/}
                </div>}
                {!!chatSearchText && <div className={`fa-icon ${styles["search-box-icon"]}`}
                    onClick={()=> {
                        setChatSearchText('');
                        handleChatSearch('')}}>
                        <FaXmark/>
                        {/*<img*/}
                        {/*    src={theme == "dark" ? "/icons8-close-50-dark.png" : "/icons8-close-50-light.png"}*/}
                        {/*/>*/}
                </div>}
            </div>
            <div onClick={() => toggleBookmarked()}
                 className="fa-icon" title={"Favorite"}>
                {isBookmarked ? (
                    <FaBookmark title="Bookmarked" />
                ) : (
                    <FaRegBookmark title="Not Bookmarked" />
                )}
                {/*<img*/}
                {/*    src={theme === "dark" ? (isBookmarked ? "/icons8-bookmark-50-marked-dark.png" : "icons8-bookmark-50-dark.png") :*/}
                {/*        (isBookmarked ? "/icons8-bookmark-50-marked-light.png" : "icons8-bookmark-50-light.png")}*/}
                {/*    alt="Bookmark"/>*/}
            </div>
        </div>)


    return (
        <div className={styles["chat-history"]}>
            {divPanel}
            {/*<a onClick={handleSearchHistory}>search..</a>*/}
            {/*<a onClick={handleFilterHistory}>filter..</a>*/}
            <div className={styles["chat-history-inner-scroll"]} ref={scrollDivRef}>
                <div className={styles["chat-history-list"]}  >
                    {chatHistory.map((chat, index) => (
                        <div key={index} className={styles["chat-history-entry"]}>
                            <div
                                // key={index}
                                onClick={() => handleChatHistoryClick(chat.id)}
                                className={`${styles["chat-history-title"]} ${chatId === chat.id ? styles["is-current"] : ""}  ${chat.isOwner ? "" : styles[theme == "dark" ? "darken" : ""]} `}
                                // className={styles["chat-history-title"]}
                                title={chat.title}
                            >
                                {chat.title}
                                {chat.is_bookmarked && (
                                    <FaBookmark size={12} className={"-main-color"}/>
                                )}

                                {/*{chat.is_bookmarked && <img className={styles["chat-history-title-bookmark"]}*/}
                                {/*    src={theme === "dark" ?"/icons8-bookmark-50-marked-dark.png" : "icons8-bookmark-50-marked-light.png"}/>*/}
                                {/*}*/}
                            </div>
                            {chat.branch_model && <div
                                className={`${styles["chat-history-model"]} ${styles[theme == "dark" ? "darken" : "lighten"]}`}>{chat.branch_model}</div>}
                            {!chat.isOwner && <div
                                className={`${styles["chat-history-owner"]} ${styles[theme == "dark" ? "darken" : "lighten"]}`}>{chat.owner}</div>}
                            {chatId === chat.id && (
                                <div className={styles["chat-history-actions"]}>
                                    <div className="fa-icon -smaller" onClick={(e) => {
                                        // handleDuplicate(chat.id)
                                        const rect = e.target.getBoundingClientRect();
                                        handleSelectiveDuplicate(chat.id, chat.title, {top: rect.top, left: rect.left});
                                        // setChatNewTitle( chat.title);
                                        // setRenameModalOpen( true);
                                        // setDuplicateModalOpen({top: rect.top, left: rect.left})
                                    }}>
                                        <FaCopy title="Fork"/>
                                        {/*<img*/}
                                        {/*    src={theme == "dark" ? "/icons8-code-fork-50--dark.png" : "/icons8-code-fork-50--light.png"}*/}
                                        {/*    alt="fork" title="fork"/>*/}
                                        {/*</a>*/}
                                    </div>

                                    {chat.isOwner && <div className="fa-icon -smaller" onClick={(e) => {
                                        setSelectedChatToAction(chat);
                                        setChatNewTitle(chat.title);
                                            // setRenameModalOpen( true);
                                            const rect = e.target.getBoundingClientRect();
                                            setRenameModalOpen({top: rect.top, left: rect.left})
                                        }}>
                                        <FaEdit title="Rename" />
                                        {/*<img*/}
                                        {/*    src={theme == "dark" ? "/icons8-edit-50-dark.png" : "/icons8-edit-50-light.png"}*/}
                                        {/*    alt="rename" title="rename"/>*/}
                                    </div>}

                                    <div onClick={() => toggleChatBookmark(chat.id)}
                                         className="fa-icon -smaller" title={"Favorite"}>
                                        {chat?.is_bookmarked ? (
                                            <FaBookmark title="Bookmarked" />
                                        ) : (
                                            <FaRegBookmark title="Not Bookmarked" />
                                        )}
                                        {/*<img*/}
                                        {/*    src={theme === "dark" ? (chat?.is_bookmarked ? "/icons8-bookmark-50-marked-dark.png" : "icons8-bookmark-50-dark.png") :*/}
                                        {/*        (chat?.is_bookmarked ? "/icons8-bookmark-50-marked-light.png" : "icons8-bookmark-50-light.png")}*/}
                                        {/*    alt="Bookmark"/>*/}
                                    </div>

                                    {chat.isOwner && <div className="fa-icon -smaller" onClick={() => {
                                            handleDeleteChat(chat.id, chat.title)
                                        }}>
                                        <FaTrash title="Delete" />
                                        {/*<img*/}
                                        {/*    src={theme == "dark" ? "/icons8-delete-30-dark.png" : "/icons8-delete-30-light.png"}*/}
                                        {/*    alt="delete" title="delete"/>*/}
                                    </div>}
                                </div>)}
                        </div>
                    ))}
                    {!chatHistoryIsAll && <div className={styles["load-more"]}>
                        <a onClick={() => loadChatHistoryMore()}>load more...</a>
                    </div>}
                </div>
            </div>
            {/*<div className={`pointer-icon ${styles["search-icon"]}`} onClick={()=> {setSearchModalOpen(true)}}>*/}
            {/*    <img src ={theme == "dark"? "/icons8-search-50-dark.png": "/icons8-search-50-light.png"} title="search"/>*/}
            {/*</div>*/}
            {isRenameModalOpen && renameModal}
            {/*{isSearchModalOpen && chatSearchModal}*/}
            {isDuplicateModalOpen && duplicateModal}
            {loading && <Busy/>}
        </div>
    )

}


export default ChatHistory;
