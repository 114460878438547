// config.js
const environment = process.env.NODE_ENV || 'development';
const config = {
    development: {
        apiUrl: 'http://localhost:8001', // Local API URL
        wsUrl: 'ws://localhost:8001', // Local API URL
        frontendUrl: 'https://localhost:3001', // Local API URL
    },
    production: {
        apiUrl: 'https://partner-boost-api.opus-software.com.br', // Cloud API URL
        wsUrl: 'wss://partner-boost-api.opus-software.com.br', // Cloud API URL
        frontendUrl: 'https://partner-boost.opus-software.com.br',
    },

};

config['frontendUrl'] = config[environment].frontendUrl
config['apiUrl'] = config[environment].apiUrl
config['wsUrl'] = config[environment].wsUrl
// config['msal-scope'] = ["user.read"]


export default config;
