import React, {useState, useEffect, useContext, useRef, useMemo} from 'react';
import styles from './Chat.module.css';
import config from "../../config";
import MaxModal from "../MaxModal/MaxModal"
import ConsoleModal from "../ConsoleModal/ConsoleModal"
import PublicChats from "../PublicChats/PublicChats"
import {AppContext} from "../../redux/AppContext";
import {
    setCurrentChatId, setCurrentUsage,
    setErrorMessage,
    setInfoMessage,
    setModel, setShowOnTop
} from "../../redux/actions";
import {ThemeContext} from "../../redux/ThemeContext";
import {useNavigate} from "react-router-dom";
import Busy from "../Busy/Busy";
import ContextModal from "../ContextModal/ContextModal";
import DragAndDrop from "../DragAndDrop/DragAndDrop";
import FullMarkdown from "../FullMarkdown/FullMarkdown";
import AssistantWrapper from "../AssistantWrapper/AssistantWrapper";
// import RecentUpdates from "../RecentUpdates/RecentUpdates";
import {useApi} from "../../hooks/useApi";
import {useAuth} from "../../context/AuthContext";
import {
    FaAngleLeft, FaArrowUp, FaCheck,
    FaCodeBranch,
    FaCompressAlt, FaEdit,
    FaExpandAlt,
    FaFileAlt,
    FaInfo,
    FaRegCheckCircle, FaRegTimesCircle, FaRobot,
    FaShareAlt, FaUndo, FaUser
} from "react-icons/fa";
import {
    FaArrowDown,
    FaArrowRight,
    FaBookmark, FaCopy, FaDisplay,
    FaDownload,
    FaFloppyDisk,
    FaGlobe,
    FaLanguage, FaPaperclip, FaPlus,
    FaTrash,
    FaWandMagicSparkles
} from "react-icons/fa6";
import {AiOutlineLoading3Quarters} from "react-icons/ai";
import {IoReload} from "react-icons/io5";
import {MdOutlineDraw} from "react-icons/md";
import {Title} from "../Headings/Heading";

let debounceTimer;
const debounce = (func, delay) => {
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
    }
}

const formatChatThread = (messages, isDisableFormat) => {
    return messages.map((message, index) => {
        if (message.role === "assistant" && !isDisableFormat) {
            return {
                ...message,
                formatted: <div className={styles["markdown-parent"]}><FullMarkdown content={message.content}/></div>
            }
        } else {
            return message
        }
    })
};


function Chat() {
    const [messages, setMessages] = useState([]);
    const [title, setTitle] = useState('');
    const [input, setInput] = useState('');
    const [systemMessages, setSystemMessages] = useState([]);
    const [contextSnippets, setContextSnippets] = useState([]);
    const [isOwner, setIsOwner] = useState(false);
    const { token, name} = useAuth();
    const { state, dispatch } = useContext(AppContext);
    const { theme } = useContext(ThemeContext);
    const { currentProject, chatId, isMobile, useDataStore, useModel, useAgent, chatLayout, temperature, modelList, isDisableFormat, showOnTop } = state;
    const [loading, setLoading] = useState(false);
    const chatEndRef = useRef(null);
    const endOfAgentProgressMessagesRef = useRef(null);
    const inputRef = useRef(null);
    const [isInputFullScreen, setInputFullScreen] = useState(false);
    const [windowFirstEntryId, setWindowFirstEntryId] = useState(null);
    const navigate = useNavigate();
    const [chatInfo, setChatInfo] = useState({
        is_bookmarked: false,
        estimateCost: undefined
    })
    const lastMessageRef = useRef(null);
    const fileInputRef = useRef(null);
    const [augmentedMessage, setAugmentedMessage] = useState(null);
    const [showAugmentedMessage , setShowAugmentedMessage] = useState(false);
    const [showSharedModal, setShowSharedModal] = useState(false);
    const [shareLoading, setShareLoading] = useState(false);
    const [sharedId, setSharedId] = useState(null);
    const [sharedList, setSharedList] = useState([]);
    const sendButtonRef = useRef(null);
    const [showAgentProgressModal, setShowAgentProgressModal] = useState(false);
    const [agentProgressMessages, setAgentProgressMessages] = useState([]);
    const [agentLoading, setAgentLoading] = useState(false);
    const [agentActionLogData, setAgentActionLogData] = useState(null);
    const [showAgentActionLog, setShowAgentActionLog] = useState(false);
    const websocketRef = useRef(null);
    const [docContent, setDocContent] = useState(null);
    const [showDocContentModal, setShowDocContentModal] = useState(false);
    const [lastIncrement, setLastsIncrement] = useState(0);
    const [flgIncrement, setFlgIncrement] = useState(0);
    const [suggestion, setSuggestion]  = useState(null);
    const [candidateInput, setCandidateInput] = useState(null);
    const [undoInput, setUndoInput] = useState(null);
    const [resubmitParameters, setResubmitParameters] = useState();
    const [focusEntryIndex, setFocusEntryIndex] = useState(null);
    // const [chatLoading, setChatLoading] = useState(false);
    const api = useApi();
    const [onTop, setOnTop] = useState(true);


    // Memoize the formatted messages
    const formattedMessages = useMemo(() => formatChatThread(messages, isDisableFormat), [messages, isDisableFormat]);

    useEffect(() => {
        setOnTop(!messages || messages.length<= 0);
    }, [messages])


    useEffect(() => {
        if (chatId) {
            loadChatThread();
        } else {
            setTimeout( ()=> {
                resetNewChat(false);
            },200);
        }
        setSharedId(null);
        if (resubmitParameters) setResubmitParameters(null);  //clear
    }, [chatId]);

    useEffect(()=> {
        if (title) {
            document.title = `PartnerBoost - ${title}`;
        } else {
            document.title = `PartnerBoost`;
        }
    }, [title]);

    useEffect(() => {
        const loadCachedSuggestion = () => {
            api.get('/api/suggest/cached').then(response => {
                setSuggestion(response.data);
            })
            .catch((error)=> { /*error handled in apiService*/})
        }
        loadCachedSuggestion()
    }, [])

    useEffect(() => {
        if (currentProject) {
            loadSnippets();
            loadSystemMessages();
            setSharedList([]);
            resetNewChat(true);
        }
    }, [currentProject]);

    useEffect(() => {
        if (inputRef?.current) {
            setTimeout(() => {
                inputRef?.current?.focus();
            }, 5000);
        }
    }, []);

    useEffect(()=> {
        if (isInputFullScreen && messages.length> 0) {

            setInputFullScreen(false);
        }
    }, [messages])

    useEffect(()=> {
        if (messages.length> 0 && flgIncrement !== lastIncrement) {
            moveScrollToLastMsg();
            // moveScrollToEnd();
            setLastsIncrement(flgIncrement);
        }
    }, [messages])

    useEffect(()=> {
        if (endOfAgentProgressMessagesRef.current) {
            endOfAgentProgressMessagesRef.current.scrollIntoView({behavior: 'smooth'})
        }
     }, [agentProgressMessages])

    useEffect(() => {
        // Cleanup function to close WebSocket connection when component unmounts
        return () => {
            if (websocketRef.current) {
                websocketRef.current.close();
            }
        };
    }, []); // This effect runs only once on mount

    const focusInput = () => {
        setTimeout(() => {
            inputRef?.current?.focus();
        }, 200);
    }

    const moveScrollToEnd= () => {
        setTimeout(() => {
            chatEndRef?.current?.scrollIntoView({ behavior: "smooth" });
        }, 10);
    }
    const moveScrollToTop= () => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth' // for smooth scrolling
            });

        }, 10);
    }

    const moveScrollToLastMsg = () => {
        setTimeout(()=> {
            // https://stackoverflow.com/questions/11039885/scrollintoview-causing-the-whole-page-to-move
            // lastMessageRef?.current?.scrollIntoView({ behavior: 'smooth'});
            // lastMessageRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start'});
            if (isMobile) {
                lastMessageRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start'});
            } else {
                lastMessageRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest'});
                // chatEndRef?.current?.scrollIntoViewIfNeeded(false);
            }
        }, 100);
    }

    const resetNewChat = (projectModified) =>{
        if (projectModified) {
            setMessages([]);
        } else {
            if (messages.length> 0) {
                const draftMessages = messages.filter(m=>!m.entry_id)
                if (draftMessages.length> 0) {
                    // if exist draft, maintain draft
                    setMessages(draftMessages);
                } else if (messages[0].role === "system") {
                    messages[0].entry_id = null;
                    setMessages(messages.slice(0,1));
                } else {
                    setMessages([]);
                }
            }
        }
        setIsOwner(true);
        setChatInfo(null);
    }

    const getLayoutSensitiveClassName = (baseClass) => {
        return `${styles[baseClass]} ${chatLayout === "row" ? styles[`${baseClass}--row`] : styles[`${baseClass}--column`]}`;
    };

    const loadSnippets = () => {
        api.get('/api/context_artifacts', {
            params: {
                project_id: currentProject.id,
                titles_only: true
            }
        }).then(response => {
            setContextSnippets(response.data);
        })
        .catch((error)=> { /*error handled in apiService*/});
    }

    const loadSystemMessages = () => {
        api.get('/api/system_messages', {
            params: {
                project_id: currentProject.id
            }
        }).then(response => {
            const loadedSystemMessages = response.data;
            setSystemMessages(loadedSystemMessages);
        })
        .catch((error)=> { /*error handled in apiService*/});
    }

    const loadChatThread = (fullLoad= true) => {
        if (chatId) {

            const skip = fullLoad?0: messages.filter( m=> m.entry_id).length;
            api.get(`/api/chats/${chatId}/thread`, {
                params: {
                    skip: skip,
                }
            })
                .then(response => {
                    setMessages(prevMessages => fullLoad ?
                        response.data.entries :
                        [...prevMessages.filter(m=>m.entry_id), ...response.data.entries]);
                    setChatInfo(response.data)
                    setTitle(response.data.title);
                    setIsOwner(response.data.isOwner);
                })
                .catch((error) => { /*error handled in apiService*/
                });
        }
    }

    const handleChatInputKeyDown = (event) => {
        if (event.ctrlKey && event.key === 'Enter') {
            // Prevent the default action to avoid any unwanted behavior
            event.preventDefault();
            if (sendButtonRef.current) {
                sendButtonRef.current.click();
            }
        }
    }

    const handleSend = async (event) => {
        if (useAgent) {
            await handleSendWS()
        } else {
            handleSendApi()
        }
        setFlgIncrement(prevState => prevState+1);
    }

    const handleSendWS = async () => {

        const onClose = ()=> {
            setLoading(false);
            setAgentLoading(false);
            websocketRef.current = null;
        }

        const isNewChat = !chatId
        if (websocketRef.current) {
            websocketRef.current.close();
        }
        const websocket = new WebSocket(`${config.wsUrl}/ws/chat/agent`);
        websocketRef.current = websocket; // Store the connection in the ref
        setLoading(true);
        setAgentLoading(true);
        websocket.onopen = () => {
            const request = chatId ? {
                message: input,
                use_model: useModel,
                chat_id: chatId,
                project_id: currentProject.id,
                data_store: useDataStore ? useDataStore[0] : null,
                agent_name: useAgent,
                token: token,
            } : {  // first message
                message: input,
                use_model: useModel,
                chat_id: null,
                project_id: currentProject.id,
                data_store: useDataStore ? useDataStore[0] : null,
                agent_name: useAgent,
                token: token,
            };
            websocket.send(JSON.stringify(request));
            setAgentProgressMessages([]);
            setShowAgentProgressModal(true);
        }
        websocket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            if (data.status === 'working') {
                const newEntry = {
                    role: "agent",
                    content: data.data,
                    entry_id: null,   // fake
                    final: false,
                }

                setAgentProgressMessages(currentMessages => [...currentMessages, newEntry])
            } else if (data.status === 'complete') {
                const newEntry = {
                    role: "agent",
                    content: data.data?.response,
                    entry_id: null,   // fake
                    final: true,
                }
                setAgentProgressMessages(currentMessages => [...currentMessages, newEntry])

                setInput('');

                // TODO: Aqui está piscando a tela. Será por causa do move scroll?..
                loadChatThread(false);

                if (isNewChat)
                    setTimeout(() => {
                        dispatch(setCurrentChatId(data.data.chat_id))
                    }, 500);
                websocket.close();
                websocketRef.current = null;
            } else if (data.status === 'error') {
                dispatch(setErrorMessage(data.data));
            }
        }
        websocket.onerror = (event) => {
            console.error( 'websocket.onerror. event=', event);
            dispatch(setErrorMessage( "WebSocket error!"));
            onClose();
        }
        websocket.onclose = (event) => {
            onClose();
        }
    }

    const updateMetric = () => {
        api.get('/api/metrics/monthly_usage/current')
            .then(response => {
                dispatch(setCurrentUsage(response.data));
            })
            .catch((error)=> { /*error handled in apiService*/});
    }

    const handleSendApi = (pInput) => {
        // event.preventDefault();
        if (loading  || !readyToSend) return;

        let presetList = messages.filter(m=> m.entry_id == null).map(m=> { return {
            role: m.role,
            content: m.content,
            image_url: m?.image_url,
        }})

        const isNewChat = !chatId
        const request = chatId ? {   // not first message
            message: input || pInput,
            use_model: useModel,
            chat_id: chatId,
            preset_list: presetList,
            project_id:  currentProject.id,
            data_store: useDataStore?useDataStore[0]:null ,
            temperature: temperature,
        } : {  // first message
            message: input || pInput,
            use_model: useModel,
            preset_list: presetList,
            chat_id: null,
            project_id:  currentProject.id,
            data_store: useDataStore?useDataStore[0]:null,
            temperature: temperature,
        };
        setLoading(true);
        // setChatLoading(true);
        api.post('/api/chat/message', request)
            .then(async response => {
                setInput('');
                setUndoInput('');
                loadChatThread(isNewChat);
                if (isNewChat)
                    setTimeout(() => {
                        dispatch(setCurrentChatId(response.data.chat_id))
                    }, 500);
                // setWindowFirstEntryId( response.data.window_first_entry_id);
                updateMetric();

            })
            .catch((error)=> { /*error handled in apiService*/})
            .finally(() => {
                setLoading(false);
                // setChatLoading(false);
            });
    };

    const handleResubmit = async () => {
        const isNewChat = !chatId
        const request = {   // not first message
            message: resubmitParameters.message,
            use_model: useModel,
            chat_id: chatId,
            project_id:  currentProject.id,
            temperature: temperature,
            entries: Object.keys(resubmitParameters.selectedEntries).filter(id => resubmitParameters.selectedEntries[id]),
            // fork_entry_id: messages[resubmitParameters.index].entry_id,
            title: resubmitParameters.title
        };
        setLoading(true);
        api.post('/api/chat/message/fork', request)
            .then(async response => {
                setInput('');
                setUndoInput('');
                loadChatThread(isNewChat);
                setTimeout(() => {
                    dispatch(setCurrentChatId(response.data.chat_id))
                }, 500);
                updateMetric();
                setResubmitParameters(null);

            })
            .catch((error)=> { /*error handled in apiService*/})
            .finally(() => {
                setLoading(false);
            });
    }

    const handleResubmitSelected= async (pos, index, title, message) => {
        setLoading(true);
        try {
            const result = await api.get(`/api/chats/${chatId}/entries-selection-list`);
            const entriesList = result.data.slice(0, result.data.findIndex(e=>e.entry_id === messages[index].entry_id))
            const selectedEntries = entriesList.reduce((acc, d) => {
                acc[d.entry_id] = true;
                return acc;
            }, {})
            setResubmitParameters({
                pos,
                index,
                title,
                message,
                entriesList: entriesList, //result.data,
                selectedEntries,
            })
        } finally {
            setLoading(false);
        }
    }

    const handleDuplicateSelection = (entry_id) => {
        setResubmitParameters((prevState) => ({
            ...prevState,
            selectedEntries: {
                ...prevState.selectedEntries,
                [entry_id]: !prevState.selectedEntries[entry_id]
            }
        }));
    };

    const handleSelectSystemMessage = (index) => {
        // setSelectedSystemMessage([systemMessages[index]]); // Only one system message can be selected at a time
        //
        setMessages(current =>  [...current, {
            role: "system",
            content: systemMessages[index].content,
            preset_id:  systemMessages[index].id,
            entry_id: null,
        }]);

    };

    const handleAddEmptyMessage = () => {
        setMessages(current =>  [...current, {
            role: "user",
            content: "",
            // preset_id:  "preset-"+new Date().getTime().toString(),
            entry_id: null,
        }]);
    }
    const handleAddEmptySystemMessage = () => {
        setMessages(current =>  [...current, {
            role: "system",
            content: "",
            // preset_id:  "preset-"+new Date().getTime().toString(),
            entry_id: null,
        }]);
    }


    const handleSelectSnippet = async (index, event) => {
        setLoading(true);
        try {
            const response = await api.get(`/api/context_artifacts/${contextSnippets[index].id}`)
            setMessages(current => [...current, {
                role: "user",
                content: response.data?.content,
                preset_id: contextSnippets[index].id,
                entry_id: null,
            }]);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteEntry = async (entryId) => {
        await api.delete(`/api/chats/${chatId}/entry/${entryId}`);
        await loadChatThread()
    }


    const handleDeletePresetEntry = (index) => {
        setMessages(prevMessages => {
            const newMessages = [...prevMessages];
            newMessages.splice(index, 1);
            return newMessages;
        });
    }

    const handleCopyContent = (content) => {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(content).then(() => {
                console.log('Content copied to clipboard');
                dispatch( setInfoMessage('Content copied to clipboard'));

                // Optionally, you can display a message to the user indicating the copy was successful.
            }).catch(err => {
                console.error('Could not copy text: ', err);
            });
        } else {
            setErrorMessage( "Not supported in this browser");
        }
    };


    const handleDisplayAugmented = async (id) => {
        const response = await api.get(`/api/augmented-message/${id}`);
        setAugmentedMessage( response.data);
        setShowAugmentedMessage( true);
    }

    const handleDisplayAgentActionLog = async (id) => {
        const response = await api.get(`/api/agent-action-log/${id}`);
        setAgentActionLogData( response.data);
        setShowAgentActionLog( true);
    }


    const augmentedMessageModal = showAugmentedMessage && (
        <MaxModal show={true} handleClose={()=> setShowAugmentedMessage(false)}>
            <>
                <div className={styles["augmented-modal-panel"]}>
                    <div onClick={() => handleCopyContent(augmentedMessage.content)}
                         className="fa-icon" title={"copy chat entry"}>
                        <FaCopy/>
                    </div>
                </div>
                {augmentedMessage.meta && (
                    <>
                        <div className={`${styles["augmented-message-modal-container"]} code-view`}>
                    {JSON.stringify(augmentedMessage.meta, null, 2)}
                        </div><hr className={"separator"}/></>)}
                <div className={`${styles["augmented-message-modal-container"]} code-view`}>
                    {augmentedMessage.content}
                </div>
            </>
        </MaxModal>
    )

    const docContentModal = showDocContentModal && (
        <MaxModal show={true} handleClose={()=> setShowDocContentModal(false)}>
            <div className={styles['doc-content-container']}>
              <Title>{docContent.doc_name}</Title>
              <div className={`$styles["doc-content-content"] code-view`}>{docContent.content}</div>
            </div>
        </MaxModal>

    )

    const showContent = (doc) => {
        setDocContent(doc);
        setShowDocContentModal(true)
    }


    const actionLogFormatter = (d) => {
        const documentFormatter = (d) => {
            // d is array of {content:str, doc_name: str}
            return (<>
                {d.map((doc, index) => (<div className={styles["agent-doc-row"]}>
                    <div className={styles["agent-doc-name"]}>{doc.doc_name}</div>
                    <div>{doc.content.slice(0,100)+'...'}<a onClick={()=> showContent(doc)}>view full content</a></div>
                </div>))}
            </>)
        }
        function object_formatter(object_key, object) {
            if (object_key == "documents") {
                return documentFormatter(object)
            } else {
                return JSON.stringify(object, (key, value) => {
                    // If the value is a string, wrap it in single quotes
                    if (typeof value === 'string') {
                        return `'${value}'`;
                    }
                    // For other types, return the value as is
                    return value;
                }, 2) // The '2' here is for pretty-printing with an indentation of 2 spaces
                    .replace(/"([^"]+)":/g, "'$1':") // Replace double quotes around keys with single quotes
                    .replace(/'/g, ""); // Remove all single quotes
            }
        }

        let detail = d.detail
        if (typeof d.detail === "string") {
            try {
                detail = JSON.parse(d.detail);
            } catch (error) {
                detail = {detail: d.detail}
            }
        }
        return (<>
            <div className={styles["agent-action-log-row-entry"]} key={9999}>
                <div className={styles["agent-log-key"]}>step</div>
                <div className={styles["agent-log-value"]}>{d.step}</div>
            </div>
            {Object.entries(detail).map(([key, value], index) => (<>
                {value && (
                <div className={styles["agent-action-log-row-entry"]} key={index}>
                    <div className={styles["agent-log-key"]}>{key}</div>
                    <div className={styles["agent-log-value"]}>{typeof value === "string"? value: object_formatter(key, value)}</div>
                </div>)}</>
            ))}
        </>)
    }

    const agentActionLogModal = showAgentActionLog && (
        <MaxModal show={true} handleClose={()=> setShowAgentActionLog(false)}>
            <>
                <div className={styles["augmented-modal-panel"]}>
                    <div onClick={() => handleCopyContent(JSON.stringify(agentActionLogData.content))}
                         className="fa-icon" >
                        <FaCopy/>
                    </div>
                </div>
                <div className={`${styles["agent-action-log-modal-container"]} code-view`}>
                    {agentActionLogData.content.map((d, index) => (
                        <div className={styles["agent-action-log-row"]} key={index}>
                            {actionLogFormatter(d)}
                        </div>
                    ))}
                </div>
            </>
        </MaxModal>
    )

    const handleMakeShared = async () => {
        setShareLoading(true);
        try {
            const response = await api.put(`/api/chats/${chatId}/make_shared`);
            setSharedId( response.data?.shared_id);
            handleCopyContent(sharedIdToUrl(response.data?.shared_id));
        } finally {
            setShareLoading(false);
        }
    }


    const handleLoadShared = async () => {
        api.get('/api/shared_chats', {
            params: {
                project_id: currentProject.id,
            }
        }).then(response => {
            setSharedList(response.data);
        })
        .catch((error)=> { /*error handled in apiService*/});
    }

    const handleDeleteShared = (shared_id) => {
        api.delete(`/api/shared_chats/${shared_id}`, {
            params: {
                project_id: currentProject.id,
            }
        }).then(response => {
            handleLoadShared();
        })
        .catch((error)=> { /*error handled in apiService*/});
    }

    const sharedIdToUrl = (shared_id) => {
        return `${config.frontendUrl}/#/shared/${shared_id}`
    }

    const sharedModal = showSharedModal && (
        <MaxModal show={showSharedModal} handleClose={()=> setShowSharedModal(false)}>
            <div>
                <div className={"title"}>Create a public link to share</div>
                <div className={styles["share-message"]}>Anyone with the link can see or share it with others, so share
                    responsibly.
                </div>
                <div className={styles["share-panel"]}>
                    <a className={styles["make-shared"]} onClick={(event) => handleMakeShared()}>
                        Create public link</a>
                    {shareLoading && <div>Loading...</div>}
                </div>

                {sharedId && (
                    <div className={styles["share-panel-result"]}>
                        <div className={`${styles["share-link"]} code-view`}>
                            {sharedIdToUrl(sharedId)}
                        </div>
                        <div onClick={() => handleCopyContent(sharedIdToUrl(sharedId))}
                             className="fa-icon">
                            <FaCopy/>
                        </div>
                    </div>)}

                <hr/>
                <div className={styles["share-panel"]}>
                    <a className={styles["make-shared"]} onClick={(event) => handleLoadShared()}>
                        Load my shared links</a>
                </div>
                {sharedList && (
                    <div className={styles["shared-list"]}>
                        {sharedList.map((d, index) => (
                            <div className={styles["shared-list-line"]} key={index}>
                                <div>{d.title}</div>
                                <div>{new Date(d.shared_id_expire_date).toDateString()}</div>
                                <div onClick={() => handleDeleteShared(d.shared_id)}
                                     className="fa-icon" title={"delete"}>
                                    <FaTrash/>
                                    {/*<img*/}
                                    {/*    src={theme == "dark" ? "/icons8-delete-30-dark.png" : "/icons8-delete-30-light.png"}/>*/}
                                </div>
                                <div onClick={() => handleCopyContent(sharedIdToUrl(d.shared_id))}
                                                                      className="fa-icon" title={"copy shared link"}>
                                    <FaCopy/>
                                    {/*<img*/}
                                    {/*    src={theme == "dark" ? "/icons8-copy-50--dark.png" : "/icons8-copy-50--light.png"} alt="Copy"/>*/}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </MaxModal>
    )

    const triggerFileInput = () => {
        // Trigger the file input click event
        fileInputRef.current.click();
    };

    const handleDropFiles = async (dropFiles) => {
        console.log( 'dropFiles', dropFiles);
        if (dropFiles.length<= 0) return;
        try {
            setLoading(true);
            for (let i = 0; i < dropFiles.length; i++) {
                // console.log(dropFiles[i]);
                await handleFileInput(dropFiles[i]);
            }
            moveScrollToEnd();
        } catch (error) {
            console.error('Error uploading file:', error);
        } finally {
            setLoading(false);
        }
    };


    const handleFileInput = async (file) => {
        // console.log( file.type)
        if (file.type === "application/pdf" || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            const maxFileSize = 100 * 1024 * 1024; // 100MB
            if (file.size > maxFileSize) {
                alert("File is too large. Maximum size is 100MB.");
                return;
            }
            const formData = new FormData();
            formData.append('file', file);
            const response = await api.post('/api/upload-file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data?.content) {
                setMessages(current => [...current, {
                    role: "user",
                    content: response.data?.content,
                    modified: true,
                    preset_id: null,
                    entry_id: null,
                }]);
            }
        } else if (file.type.startsWith( 'image')) {
            const formData = new FormData();
            formData.append('file', file);
            const response = await api.post('/api/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            // setUploadImageUrl(response.data.file_url);

            setMessages(current =>  [...current, {
                role: "user",
                content: null,
                // preset_id:  response.data.file_url,
                image_url: response.data.file_url,
                entry_id: null,
            }]);
        } else {   // handle as text an try to read
            const maxFileSize = 512 * 1024; // 512KB
            if (file.size > maxFileSize) {
                alert("File is too large. Maximum size is 512KB.");
                return;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
                setMessages(current => [...current, {
                    role: "user",
                    content: e.target.result,
                    preset_id: null,
                    entry_id: null,
                }]);
            };
            reader.onerror = (e) => {
                console.error("Error reading file", e);
            };
            reader.readAsText(file);
        }
    }

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        await handleDropFiles(event.target.files);
    };

    const inputPlaceHolder = (() => {
        let prompt = "Enter your prompt..";
        return prompt
    })();

    const undo = () => {
        if (!undoInput) return;

        const tmp = input;
        setInput(undoInput);
        setUndoInput(tmp);
    }

    const translate = () => {
        if (!input) return;

        setLoading(true);
        api.post(`/api/llm_task/translate`, {
            prompt: input
        }).then(response => {
            setCandidateInput( response.data);
        })
            .catch((error)=> { /*error handled in apiService*/})
            .finally(() => {
                setLoading(false);
            });
    }

    const improve_prompt = () => {
        if (!input) return;

        setLoading(true);
        api.post(`/api/llm_task/improve_prompt`, {
            prompt: input
        }).then(response => {
            setCandidateInput( response.data);
        })
            .catch((error)=> { /*error handled in apiService*/})
            .finally(() => {
                setLoading(false);
            });
    }

    const candidateInputDiv = candidateInput && (
        <div className={styles['candidate-window']}>
            <div className={`${styles['candidate-output-container']} code-view`}>
                {candidateInput}
            </div>
            <div className={styles['candidate-panel']}>
                <div
                    // type="submit"
                        className={`fa-icon -larger-xx`}
                        // className={`${isMobile? "icon-button-larger": "icon-button-larger"}`}
                        onClick={(event) => {
                            setCandidateInput(null)
                        }}
                        title={"Reject"}
                >
                    <FaRegTimesCircle/>
                </div>
                <div
                        className={`fa-icon -larger-xx -accept`}
                        onClick={(event) => {
                            setUndoInput(input);
                            setInput(candidateInput);
                            setCandidateInput(null);
                        }}
                        title={"Accept and replace the current prompt"}
                >
                    <FaRegCheckCircle/>
                </div>
            </div>
        </div>
    )


    const undoButton = (<div
        className={`fa-icon -larger ${isMobile? styles["input-view-icon"]:""}`}
        onClick={(event) => {
            undo()
        }}
        title={"Undo suggested prompt"}
    >
        <FaUndo/>
    </div>)


    const translateButton = (<div
                                     className={`fa-icon -larger ${isMobile? styles["input-view-icon"]:""}`}
                                     onClick={(event) => {
                                         translate()
                                     }}
                                     title={"Translate to English"}
    >
        <FaLanguage/>
    </div>)


    const improvePromptButton = (<div
                className={`fa-icon -larger ${isMobile? styles["input-view-icon"]:""}`}
                onClick={(event) => {
                    improve_prompt()
                }}
                title={"Improve my prompt!"}
        >
            <FaWandMagicSparkles/>
        </div>)


    const readyToSend = input || messages.some(m=> m.entry_id==null && m.role === "user");

    const [promptActivate, setPromptActivate] = useState(false);

    const chatInputPanel = (
        <>
            {isMobile && isInputFullScreen && (
                <div className={`${styles["chat-bottom--fullscreen"]}`}>
                <textarea ref={inputRef}
                          className={`code ${styles["chat-input"]} ${styles["fullscreen-input"]}`}
                          value={input}
                          onChange={e => setInput(e.target.value)}
                          placeholder={inputPlaceHolder}
                          onFocus = {()=> setPromptActivate(true)}
                          onBlur = {()=> setPromptActivate(false)}
                />
                    <div className={styles["input-fullscreen-panel"]}>
                        {undoInput && undoButton}
                        {translateButton}
                        {improvePromptButton}
                        {/*{imageUploadPanel(`icon-button-larger ${styles["input-view-icon"]}`)}*/}
                        <div className={`fa-icon -larger ${styles["input-view-icon"]}`} onClick={() => {
                            setInputFullScreen(false);
                        }}
                             title={"switch to chat view"}
                        >
                            <FaAngleLeft/>
                        </div>

                        <div
                                className={`fa-icon -larger -blue ${styles["input-view-icon"]} ${loading?"loading-anim":""}`}
                                onClick={(event) => {
                                    handleSend(event);
                                }}
                                tabIndex={1}
                                // disabled={loading  || !readyToSend}
                        >
                            {loading? <AiOutlineLoading3Quarters/>: <FaArrowRight/>}
                        </div>
                    </div>
                </div>
            )}


            {!isMobile && isInputFullScreen && (
                <div className={`${styles["chat-bottom--fullscreen"]}`}>
                <textarea ref={inputRef}
                          className={`code ${styles["chat-input"]} ${styles["fullscreen-input"]}`}
                          value={input}
                          onChange={e => setInput(e.target.value)}
                          onKeyDown={handleChatInputKeyDown}
                          placeholder={inputPlaceHolder}
                          // onFocus = {()=> setPromptActivate(true)}
                          // onBlur = {()=> setPromptActivate(false)}
                />
                    <div className={styles["input-fullscreen-panel"]}>
                        <div className="fa-icon" onClick={() => {
                            setInputFullScreen(false);
                            focusInput();
                        }}>
                            <FaCompressAlt />
                        </div>
                        <div className={`fa-icon`} onClick={() => {
                            setInputFullScreen(false);
                        }}
                             title={"switch to chat view"}
                        >
                            <FaAngleLeft />
                        </div>
                        <div
                            className={`fa-icon -blue ${loading ? "loading-anim" : ""}`}
                                onClick={(event) => {
                                    handleSend(event);
                                }}
                                tabIndex={1}
                                ref={sendButtonRef}
                            // disabled={loading || !readyToSend}
                        >
                            {loading? <AiOutlineLoading3Quarters/>: <FaArrowRight/>}
                        </div>
                        {/*{imageUploadPanel("icon-button-larger")}*/}
                        {undoInput && undoButton}
                        {translateButton}
                        {improvePromptButton}
                    </div>
                </div>
            )}


            {!isMobile && !isInputFullScreen && (
                <div className={getLayoutSensitiveClassName("chat-bottom")}>
                    <div className={`${getLayoutSensitiveClassName("panel-input")} ${promptActivate?styles["prompt-active"]:""}`}>
                        {chatLayout === "column" && (<div className={styles["input-panel-left-container"]}>
                            {undoInput && undoButton}
                            {translateButton}
                            {improvePromptButton}
                        </div>)}
                        <textarea ref={inputRef}
                                  className={`code ${getLayoutSensitiveClassName("chat-input")}`}
                                  value={input}
                                  onChange={e => setInput(e.target.value)}
                                  onKeyDown={handleChatInputKeyDown}
                                  placeholder={inputPlaceHolder}
                                  onFocus = {()=> setPromptActivate(true)}
                                  onBlur = {()=> setPromptActivate(false)}
                        />
                        <div className={getLayoutSensitiveClassName("input-panel-right")}>
                            {chatLayout === "column" && <div className="fa-icon"
                                                             onClick={() => {
                                                                 setInputFullScreen(true);
                                                                 focusInput();
                                                             }}
                                                             title={"maximize input panel"}
                            >
                                <FaExpandAlt/>
                            </div>}
                            <div className={styles["button-panel"]}>
                                {chatLayout !== "column" && (<>
                                    {undoInput && undoButton}
                                    {translateButton}
                                    {improvePromptButton}
                                </>)}
                                <div
                                        className={`fa-icon -larger -blue ${loading ? "loading-anim" : ""}`}
                                        onClick={(event) => handleSend(event)}
                                        ref={sendButtonRef}
                                        // disabled={loading || !readyToSend}
                                >
                                    {loading? <AiOutlineLoading3Quarters/>: <FaArrowRight/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}
            <input
                type="file"
                // accept="application/pdf,text/plain"
                style={{display: 'none'}}
                ref={fileInputRef}
                onChange={handleFileChange}
            />

        </>
    )

    const [systemInContextMessageListModalClickPosition, setSystemInContextMessageListModalClickPosition] = useState(null);
    const systemInContextMessageListModal = !!systemInContextMessageListModalClickPosition && (
        <ContextModal
            clickPosition = {systemInContextMessageListModalClickPosition}
                  handleClose={() => setSystemInContextMessageListModalClickPosition(null)} closeLabel={"Close"}>

            <div  className={styles["modal-container"]}>
                <div className={`${styles["title-with-icon"]} ${styles["modal-header"]}`}>
                    <div className="fa-icon"
                         onClick={() => {
                             handleAddEmptySystemMessage();
                             setSystemInContextMessageListModalClickPosition(null);
                         }}
                         title={"Add empty system message"}
                    >
                        <FaPlus/>
                    </div>
                    <div className="fa-icon"
                         onClick={() => {
                             if( messages.filter(m=> !m.entry_id).length > 0)
                             {
                                 if (!window.confirm("Your draft context will be lost. Do you want to continue?")) {
                                     return;
                                 }
                             }
                             navigate('/system_message')
                         }}
                         title={"Edit your system messages"}
                    >
                        <FaEdit/>
                    </div>
                </div>
                <div className={styles["scroll-list-outside-window"]}>
                    <div className={styles["scroll-list-inner-container"]}>
                        {systemMessages.map((option, index) => (
                            <div className={"selection-list-item"} key={index} onClick={() => {
                                handleSelectSystemMessage(index);
                                setSystemInContextMessageListModalClickPosition(null);
                            }}>
                            <div className={styles['modal-list-title']}>{option.title}</div>
                                {/*<div className={`${styles['modal-list-content']} code-view`}>{option.content}</div>*/}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </ContextModal>)


    const agentProgressModal = showAgentProgressModal && (
        <ConsoleModal show={true} handleClose={()=> setShowAgentProgressModal(false)}>
            <div className={styles["modal-list-container"]}>
                <div className={styles["model-list-search-result-container"]}>
                    <div className={`${styles["model-agent-progress-inner-scroll"]} code-view`}>
                        {agentProgressMessages.map((m, index) => (
                            <>
                                {!m.final && <div className={styles["progress-row"]} key={index}>
                                    {m.content}
                                </div>}
                                {m.final && <div className={`${styles["progress-row-final"]} code-view`} key={index}>
                                    {m.content}
                                </div>}
                            </>
                        ))}
                        {agentLoading && (
                            <div className={`${styles["working-img"]} fa-icon`}>
                                <AiOutlineLoading3Quarters />
                            </div>)}
                        {!agentLoading && <div className={styles["progress-modal-panel"]}>
                            <button className="button" onClick={() => setShowAgentProgressModal(false)}>
                                Close
                            </button>
                        </div>}
                        <div className={styles["zero-height"]} ref={endOfAgentProgressMessagesRef}/>
                    </div>
                </div>
            </div>
        </ConsoleModal>
    )

    const [contextListModalClickPosition, setContextListModalClickPosition] = useState(null);
    const contextListModal = !!contextListModalClickPosition && (
        <ContextModal
            clickPosition = {contextListModalClickPosition}
            handleClose={() => setContextListModalClickPosition(null)} closeLabel={"Close"}>

            <div className={styles["modal-content-container"]}>
                <div className={styles["title-with-icon"]}>
                    <div className="fa-icon"
                         onClick={() => {
                             handleAddEmptyMessage();
                             setContextListModalClickPosition(null);
                         }}
                         title={"Add empty context"}
                    >
                        <FaPlus/>
                    </div>
                    <div className="fa-icon"
                         onClick={() => {
                             if (messages.filter(m => !m.entry_id).length > 0)
                             {
                                 if (!window.confirm("Your draft context will be lost. Do you want to continue?")) {
                                     return;
                                 }
                             }
                             navigate('/context_artifact')
                         }}
                         title={"Edit your predefine context"}
                    >
                        <FaEdit/>
                    </div>
                </div>
                <div className={styles["modal-list-container-scroll"]}>
                    {contextSnippets.map((option, index) => (
                        <div className={"selection-list-item"} key={index} onClick={async () => {
                            await handleSelectSnippet(index);
                            setContextListModalClickPosition(null);
                        }}>
                            <div className={styles['modal-list-title']}>{option.title}</div>
                            {/*<div className={`${styles['modal-list-content']} code-view`}>{option.content}</div>*/}
                        </div>
                    ))}
                </div>
            </div>
        </ContextModal>
    )

    const handleSaveOrUpdateMessage = async (index, title) => {
        const m = messages[index]
        let response = null
        const role = m.role
        try {
            setLoading(true);
            if (m.role === "system") {
                if (m.preset_id) {
                    response = await api.put(`/api/system_messages/${m.preset_id}`,
                        {content: m.content}   //TDOO: Testar!
                    );
                } else {
                    response = await api.post('/api/system_messages',
                        {content: m.content, title: title, project: currentProject.id}
                    );
                }
            } else { //user
                if (m.preset_id) {
                    response = await api.put(`/api/context_artifacts/${m.preset_id}`,
                        {content: m.content}   //TDOO: Testar!
                    );
                } else {
                    response = await api.post('/api/context_artifacts',
                        {content: m.content, title: title, project: currentProject.id}
                    );
                }
            }
            const newMessages = [...messages]
            if (!m.preset_id && response?.data?.id) {
                newMessages[index].preset_id = response?.data?.id;
            }
            newMessages[index].modified = false;
            setMessages(newMessages);

            if (role === "system") {
                loadSystemMessages()
            } else {
                loadSnippets()
            }
        } finally {
            setLoading(false);
        }
    }

    const [saveModifiedMessage,setSaveModifiedMessage] = useState(null);
    const [editMessageTitle,setEditMessageTitle] = useState(null);

    const modifyModal = !!saveModifiedMessage && (
        <ContextModal
            clickPosition = {saveModifiedMessage.pos}
            handleClose={() => setSaveModifiedMessage(null)} closeLabel={"Close"}>
            <div className={styles["modal-content-container"]}>
                <div className={styles["modal-content-container-header"]}></div>
                <div>Name:</div>
                <input type={"text"}
                       autoFocus
                       className = {"input"}
                       value={editMessageTitle}
                       onChange={e => setEditMessageTitle(e.target.value)}/>
                <button
                    className={"button"}
                    disabled={!editMessageTitle}
                    onClick={async () => {
                        await handleSaveOrUpdateMessage(saveModifiedMessage.index, editMessageTitle);
                        setSaveModifiedMessage(null);
                    }}
                >Save
                </button>
            </div>
        </ContextModal>
    )

    const [showMeta, setShowMeta] = useState(null);

    const metaModal = !!showMeta && (
        <ContextModal
            clickPosition={showMeta.pos}
            handleClose={() => setShowMeta(null)}
        >
            <div className={`${styles["message-entry-meta"]}`}>
                <div>{showMeta.meta.model}</div>
                <div
                    title={JSON.stringify(showMeta.meta.usage)}>{showMeta.meta.usage?.total_tokens}</div>
                <div>${showMeta.meta.estimate_price}</div>
            </div>
        </ContextModal>
    )

    const resubmitModal = !!resubmitParameters && (
        <ContextModal
            clickPosition={resubmitParameters.pos}
            handleClose={() => setResubmitParameters(null)}
            nonblocking={true}
            closeLabel={"Close"}>
            <div className={styles["resubmit-container"]}>

                <div className={styles["duplicate-modal-entries"]}>
                    {(resubmitParameters.entriesList || []).map((m, index) => (
                        <div className={`${styles["duplicate-modal-row"]} list-item`}
                             onClick={() => handleDuplicateSelection(m.entry_id)}
                        >
                            <div>
                                {!!resubmitParameters.selectedEntries[m.entry_id] && <div className={"fa-icon -accept"}><FaCheck/></div>}
                                    {/*<img src="/icons8-checked-48.png" alt="selected"/>*/}
                            </div>
                            {/*<div>{m.role}</div>*/}
                            <div className={`${styles["duplicate-modal-icon-role"]} fa-icon`}>
                                {m.role === "system" && <FaDisplay />}
                                {m.role === "user" && <FaUser/>}
                                {m.role === "assistant" && <FaRobot />}
                            </div>
                            <div>{m.content}</div>
                        </div>
                    ))}
                </div>
                <div className={styles["resubmit-panel-title-panel"]}>
                    Model: <select value={useModel}
                            onChange={(e) => dispatch(setModel(e.target.value))}
                    >
                        {modelList.map((m, idx) => (
                            <option key={idx} value={m.name}>{m.name}</option>
                        ))}
                    </select>
                </div>
                <div className={styles["resubmit-textarea-parent"]}>
                    <AssistantWrapper
                        notifyImprovedText={(value) => setResubmitParameters(prev=>({...prev, message: value}))}
                        message = {resubmitParameters.message}
                        type={"prompt"}
                    />
                    <textarea
                    className={`code ${styles["resubmit-container-textarea"]}`}
                        value={resubmitParameters.message}
                        onChange={e => setResubmitParameters(prevState => ({...prevState, message: e.target.value}))}
                        autoFocus
                    />
                </div>
                {/*</div>*/}
                <div className={styles["resubmit-panel"]}>
                    <div className={styles["resubmit-panel-title-panel"]}>
                        New title: <input type={"text"} value={resubmitParameters.title}
                                          className={styles["resubmit-panel-title-input"]}
                                          onChange={e => {
                                              setResubmitParameters((prevState) => ({
                                                  ...prevState,
                                                  title: e.target.value
                                              }));
                                          }}/>
                    </div>
                    <div className={`fa-icon -larger -blue ${loading?"loading-anim":""}`}
                         onClick={async () => {
                             await handleResubmit();
                         }}>
                        {loading? <AiOutlineLoading3Quarters/>: <FaArrowRight/>}
                    </div>
                </div>
            </div>
        </ContextModal>
    )


    const handleClickSysMessage= (e) => {
        if (onTop) {
            dispatch(setShowOnTop(showOnTop===1?0:1))
        } else {
            const rect = e.target.getBoundingClientRect();
            setSystemInContextMessageListModalClickPosition({top: rect.top, left: rect.left})
        }
    }

    const handleClickContext = (e) => {
        if (onTop) {
            dispatch(setShowOnTop(showOnTop===2?0:2))
        } else {
            const rect = e.target.getBoundingClientRect();
            setContextListModalClickPosition({top: rect.top, left: rect.left})
        }
    }

    const predefinedContentToolbar =   (
        // <DropzoneWrapper onDrop={handleDrop}>
        <div className={styles["predefined-content-toolbar"]}>
            <div className={`fa-icon ${(onTop && showOnTop===1) && "-color-blue"} `}
                 onClick={handleClickSysMessage}
            >
                <FaDisplay title="Select, add or edit a predefined system message"/>
            </div>
            <div className={`fa-icon ${(onTop && showOnTop===2) && "-color-blue"}`}
                 onClick={handleClickContext}
                 title="Select, add or edit a predefined system message"
            >
                <FaFileAlt/>
            </div>
            <div className={"fa-icon"}
                 onClick={triggerFileInput}
                 title="Click or drag and drop an image file, pdf, xlsx or text file"
            >
                <FaPaperclip/>
            </div>
            <div className={"fa-icon"}
                 onClick={(e)=> {
                     handleAddEmptyMessage();
                 }}
                 title="Add new context area"
            >
                <FaPlus/>
            </div>
        </div>
    )

    const suggest = () => {
        setLoading(true);
        api.get('/api/suggest').then(response => {
            setSuggestion(response.data);
        })
            .catch((error) => { /*error handled in apiService*/
            })
            .finally(() => setLoading(false));
    }

    const initSuggestionChat = async (suggestionText) => {
        const newInput = `You said: \`\`\`${suggestionText}\`\`\`.\nTell me more!`;
        setInput(newInput);
        await handleSendApi(newInput);
        setFlgIncrement(prevState => prevState + 1);
    }


    const discoverDiv = (<>
        <div>
            <div>
                <div  className={styles["inline-button-and-text"]} >
                    <Title>Discover</Title>
                    <a className={"fa-icon"}
                       onClick={() => suggest()}>
                        <FaArrowRight/>
                    </a>
                </div>
                <div className={styles["suggest-note"]}>* Get topic suggestions based on your chat history once a day</div>
            </div>
        </div>
        {suggestion && <div className={`${styles["suggestion-container"]}`}>
            {suggestion.suggestions.map((d,index) => (<div className={styles["suggestion-line"]}
                                                           onClick={() => initSuggestionChat(d.description)}
                                                           key={index}
            >
                <div className={styles["suggestion-line-topic"]}>{d.topic}</div>
                <div className={`${styles["suggestion-line-description"]} code-view`}>{d.description}</div>
            </div>))}
        </div>}
        </>
    )



    const initialOptions = useMemo(()=> (<div className={styles["chat-intro"]}>
        <div className={styles["intro-box"]}>
            {/*<div className={styles["chat-intro-name"]}>*/}
            {/*    <div>Hello, {name.split(' ')[0]}!</div>*/}
            {/*    <div>How can I help you today?</div>*/}
            {/*</div>*/}
            {isOwner && predefinedContentToolbar}
            {/*<a onClick={() => setAdvancedMode(true)}>Show advanced options</a>*/}
            {/*<RecentUpdates/>*/}
            {/*<PublicChats/>*/}

            {/*{discoverDiv}*/}
        </div>
        {showOnTop===1 && !!systemMessages && systemMessages.length>0 && <div className={styles["ontop-sys-message"]}>
            {systemMessages.map((option, index) => (
                <div className={"selection-list-item"} key={index} onClick={() => {
                    handleSelectSystemMessage(index);
                }}>
                    <div className={styles['modal-list-title']}>{option.title}</div>
                </div>
            ))}
        </div>}
        {showOnTop===2 && !!contextSnippets && contextSnippets.length>0 && <div className={styles["ontop-context-snippet"]}>
            {contextSnippets.map((option, index) => (
                <div className={"selection-list-item"} key={index} onClick={async () => {
                    await handleSelectSnippet(index);
                }}>
                    <div className={styles['modal-list-title']}>{option.title}</div>
                </div>
            ))}
        </div>}
    </div>), [systemMessages, showOnTop])

    const sanitizeFilename = (name) => {
        return name.replace(/[/\\?%*:|"<>]/g, '-');
    }

    const downloadChat = (text, filename) => {
        if (!messages) return;

        text = messages.map(m => `${m.role}: ${m.content}`).join('\n\n');

        const blob = new Blob([text], { type: 'text/plain' });

        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = "Opus Boost for Partner - "+ sanitizeFilename(title || "Chat")+ ".txt";

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const toggleBookmark = () => {
        api.put(`/api/bookmarks/${chatId}/toggle`).then(response => {
            setChatInfo(prevState => ({...prevState, is_bookmarked: response.data}));
        })
        .catch((error)=> { /*error handled in apiService*/})
    }
    const togglePublic = () => {
        if (!chatInfo?.public_at) {
            if (!window.confirm("Making this chat public will display it on teammates' home screens in anonymous way for a few days. You can undo this change at any time. Confirm?")) {
                return;
            }
        }

        api.put(`/api/chats/${chatId}/public`).then(response => {
            setChatInfo(prevState => ({...prevState, public_at: response.data}));
        })
            .catch((error)=> { /*error handled in apiService*/})
    }


    return (
        <div className={getLayoutSensitiveClassName("chat-top-container")}>
            <DragAndDrop onFileDrop={handleDropFiles}/>
            <div className={getLayoutSensitiveClassName("chat-top")}>
                <div className={styles["chat-top-inner-scroll"]}>
                    {messages.length <= 0 && initialOptions}
                    {messages.length > 0 && (
                        <div className={`code-view-in-chat ${styles["chat-conversation-top"]}`}>
                            {chatId && <div className={styles["chat-thread-header"]}>
                                <Title>{title}</Title>
                                <div className={styles["chat-thread-header-buttons"]}>
                                    {/*{isOwner && <div onClick={() => togglePublic()}*/}
                                    {/*     className="fa-icon" title={"Toggle public status"}>*/}
                                    {/*    {chatInfo?.public_at ? (*/}
                                    {/*        <FaGlobe className={"-main-color"}/>*/}
                                    {/*    ) : (*/}
                                    {/*        <FaGlobe/>*/}
                                    {/*    )}*/}
                                    {/*</div>}*/}
                                    <div onClick={() => toggleBookmark()}
                                         className="fa-icon" title={"Favorite"}>
                                        {chatInfo?.is_bookmarked ? (
                                            <FaBookmark className={"-main-color"}/>
                                        ) : (
                                            <FaBookmark/>
                                        )}
                                    </div>
                                    {isOwner && <div onClick={() => setShowSharedModal(true)}
                                         className="fa-icon" title={"share"}>
                                        <FaShareAlt/>
                                    </div>}
                                    <div onClick={() => downloadChat()}
                                         className="fa-icon" title={"download"}>
                                        <FaDownload/>
                                    </div>
                                </div>
                            </div>}

                            {formattedMessages.map((message, index) => <>
                                <div key={index}
                                     className={`${styles[message.role]} 
                                     ${styles["role-div"]} ${message.entry_id==null?styles["chat-entry-preset"]:''}`}
                                    onPointerEnter={()=> setFocusEntryIndex(index)}
                                     onPointerLeave={()=> setFocusEntryIndex(null)}
                                >
                                    {windowFirstEntryId && windowFirstEntryId === message.entry_id &&
                                        <hr className={styles["chat-conversation-start-window"]}/>
                                    }
                                    {index === messages.length - 1 &&
                                        <div className={styles["zero-height"]} ref={lastMessageRef}/>}

                                    <div className={styles["icon-role"]}>
                                        {message.role === "system" && <img
                                            src={theme === "dark" ? "/icons8-system-50--dark.png" : "/icons8-system-50--light.png"}
                                        />}
                                        {message.role === "user" && <img
                                            src={theme === "dark" ? "/icons8-user-60-dark.png" : "/icons8-user-60-light.png"}
                                        />}
                                        {message.role === "assistant" && <img
                                            src={
                                            message.meta?.company === "MISTRAL"? "/mistral.png":
                                                (message.meta?.company === "GEMINI"? "/gemini.png":  (theme === "dark" ? "/icons8-chatgpt-50-dark.png" : "/icons8-chatgpt-50-light.png"))}
                                        />}
                                    </div>

                                    <div className={styles["message-entry-line"]}>
                                        {/*ref={index === messages.length - 1 ? lastMessageRef : null}>*/}
                                        <div className={`markdown ${styles["message-entry-message"]}`}>
                                            {/*TODO: TextWithFormulas faz subir a tela inteira, em algumas situacoes, quando move scroll para ultima mensagem */}
                                            {message.role === "assistant" && !isDisableFormat? (<>
                                                    {message.formatted ||
                                                        <div className={styles["markdown-parent"]}><FullMarkdown
                                                            content={message.content}/></div>}
                                                    {/*<div>----------------------------------</div>*/}
                                                    {/*<CodeWithHighlighting text={message.content}/>*/}
                                                </>
                                            ) : (
                                                message.entry_id? <div className={"font-mono"}>{message.content}</div>:
                                                    (
                                                    <div
                                                        className={"font-mono"}
                                                        contentEditable
                                                        data-placeholder="Enter text here..."
                                                        suppressContentEditableWarning={true}
                                                        onBlur={(event)=> {
                                                            setMessages ((prevMessages ) => {
                                                                let newMessages = [...prevMessages ];
                                                                newMessages[index].content = event.target.innerText
                                                                newMessages[index].modified = true
                                                                return newMessages;
                                                            });
                                                        }}>
                                                        {message.content}
                                                    </div>
                                                )
                                            )}
                                            {message.image_url && (
                                                <div>
                                                    <a href={message.image_url} target="_blank"
                                                       rel="noopener noreferrer">
                                                        <img className={styles["image-in-thread"]}
                                                             src={message.image_url}/>
                                                    </a>
                                                </div>
                                            )}
                                        </div>

                                        <div className={`${styles["message-entry-commands"]} ${index !== focusEntryIndex && !isMobile && styles["opaque-invisible"]}`}>
                                            {!message.entry_id && index>0 && !messages[index-1].entry_id && (
                                                <div onClick={() => {
                                                        setMessages ((prevMessages ) => {
                                                            let temp = prevMessages[index-1];
                                                            let newMessages = [...prevMessages ];
                                                            newMessages[index-1] = messages[index];
                                                            newMessages[index] = temp;
                                                            return newMessages;
                                                        });
                                                    }}
                                                     className="fa-icon" title={"move up"}>
                                                    <FaArrowUp/>
                                                </div>
                                            )}
                                            {!message.entry_id && (
                                                <div onClick={() => handleDeletePresetEntry(index)}
                                                     className="fa-icon -smaller" title={"delete chat entry"}>
                                                    <FaTrash/>
                                                </div>
                                            )}
                                            {message.entry_id && isOwner && message.role != "system" && (
                                                <div onClick={() => handleDeleteEntry(message.entry_id)}
                                                     className="fa-icon -smaller" title={"delete chat entry"}>
                                                    <FaTrash/>
                                                </div>
                                            )}
                                            {!!message.content && <div onClick={() => handleCopyContent(message.content)}
                                                 className="fa-icon -smaller" title={"copy chat entry"}>
                                                <FaCopy/>
                                            </div>}
                                            {message.entry_id && message.role === "user" && (
                                                <div onClick={(event) => {
                                                        const rect = event.target.getBoundingClientRect();

                                                        handleResubmitSelected(
                                                            {top: rect.bottom, left: rect.right},
                                                            index,
                                                            title,
                                                            message.content
                                                        )

                                                        // setResubmitParameters({
                                                        //     pos: {top: rect.bottom, left: rect.right},
                                                        //     index,
                                                        //     title,
                                                        //     message: message.content,
                                                        // });
                                                     }}
                                                     className="fa-icon -smaller" title={"branch from here"}>
                                                    <FaCodeBranch/>
                                                {/*    <img*/}
                                                {/*        src={theme == "dark" ? "/icons8-branch-64--dark.png" : "/icons8-branch-64--light.png"}/>*/}
                                                </div>
                                            )}
                                            {!message.entry_id && message.modified && !message.image_url && (
                                                <div onClick={async (e) => {
                                                    if (message.preset_id) {
                                                        await handleSaveOrUpdateMessage(index, null)
                                                    } else {
                                                        const rect = e.target.getBoundingClientRect();
                                                        setEditMessageTitle('');
                                                        setSaveModifiedMessage({
                                                            pos: {top: rect.top, left: rect.left},
                                                            index
                                                        })
                                                    }
                                                }}
                                                     className="fa-icon -smaller" title={"Save the edited context for future use."}>
                                                    <FaFloppyDisk/>
                                                </div>
                                            )}
                                            {/* meta   */}
                                            {!!message.meta && (
                                                <div
                                                    title={JSON.stringify(message.meta)}
                                                    // onMouseEnter={(event) => {
                                                    //     const rect = event.target.getBoundingClientRect();
                                                    //     setShowMeta({
                                                    //         pos: {top: rect.bottom, left: rect.right},
                                                    //         index,
                                                    //         title,
                                                    //         meta: message.meta
                                                    //     })
                                                    //  }}
                                                    //  onMouseLeave = {()=> setShowMeta(null)}
                                                     className="fa-icon -smaller"
                                                >
                                                    <FaInfo  />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className={styles["message-footer"]}>
                                        <div className={styles["message-footer-left"]}>
                                            {message.augmented_message_log_id && (<>
                                                <div
                                                    onClick={() => handleDisplayAugmented(message.augmented_message_log_id)}
                                                    className="fa-icon"
                                                    title={"view augmented context for this question"}>
                                                    <IoReload/>
                                                </div>
                                            </>)}
                                            {message.agent_action_id && (<>
                                                <div
                                                    onClick={() => handleDisplayAgentActionLog(message.agent_action_id)}
                                                    className="fa-icon"
                                                    title={"view agent action log"}>
                                                    <FaRobot/>
                                                    {/*<img*/}
                                                    {/*    src={theme == "dark" ? "/icons8-robot-log-50--dark.png" : "/icons8-robot-log-50--light.png"}/>*/}
                                                </div>
                                            </>)}
                                        </div>
                                    </div>
                                </div>
                            </>)}
                            {/*{chatLoading && <div className={`${styles['loading-chat']} loading-anim`}>*/}
                            {/*    <div className={`fa-icon -larger-x -color-blue`}>*/}
                            {/*    <AiOutlineLoading3Quarters />*/}
                            {/*    </div></div>}*/}
                            {!!chatInfo?.estimate_total_cost && <div className={styles["estimate-cost"]}>
                                Estimated cost: ${chatInfo.estimate_total_cost.toFixed(4)}
                            </div>}
                            {isOwner && predefinedContentToolbar}
                            <div className={styles["zero-height"]} ref={chatEndRef}/>
                        </div>
                    )}
                    {isMobile && (<>
                        <div className={styles["chat-fullscreen-panel"]}>
                            {loading && <div className={`fa-icon`}>
                                <AiOutlineLoading3Quarters />
                                {/*<img className={styles["loading-anim"]} src={theme == "dark"? "/icons8-loading-50-dark.png": "/icons8-loading-50-light.png"}*/}
                                {/*></img>*/}
                            </div>}
                            {!loading && <>
                            {messages.length> 0 &&<div className={`fa-icon -larger-xx`} onClick={() => moveScrollToTop()}>
                                <FaArrowUp/>
                            </div>}
                            <div className={`fa-icon -larger-xx  ${styles['input-view-icon']}`} onClick={()=>{
                                setInputFullScreen(true);
                            }}
                                              title={"switch to input view"}
                            >
                                <MdOutlineDraw />
                            </div>
                            </>}
                            {messages.length> 0 && <div className={`fa-icon -larger-xx`} onClick={() => moveScrollToEnd()}>
                                <FaArrowDown/>
                            </div>}
                        </div>
                        </>
                    )}

                </div>
            </div>

            {isOwner && chatInputPanel}
            {augmentedMessageModal}
            {sharedModal}
            {agentProgressModal}
            {agentActionLogModal}
            {docContentModal}
            {candidateInputDiv}
            {loading && <Busy/>}
            {systemInContextMessageListModal}
            {contextListModal}
            {modifyModal}
            {resubmitModal}
            {metaModal}
        </div>
    );
}

export default Chat;
